import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  Tooltip,
  ChartOptions,
  ChartData,
  LinearScale,
  TimeScale,
  Filler,
  Title,
  CategoryScale,
  ChartDataset,
  Legend,
} from "chart.js";
import { Line, getElementAtEvent } from "react-chartjs-2";
import { LineWithErrorBarsChart } from "chartjs-chart-error-bars";
import annotationPlugin from "chartjs-plugin-annotation";

import deepmergeIgnoreUndefined from "../../utils/deepmerge";
import { dataContext } from "../DataContextProvider";
import { graphRangeContext } from "../GraphRangeContextProvider";
import isValidNumber from "../../utils/isValidNumber";
import { formatter } from "../Format";

ChartJS.register(
  LineWithErrorBarsChart,
  annotationPlugin,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Title,
  Legend
);

const defaultOptions: ChartOptions<"line"> = {
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "rgb(255, 255, 255)",
      },
    },
    title: {
      position: "top",
      fullSize: false,
      color: "rgb(255, 255, 255)",
    },
    tooltip: {
      callbacks: {
        beforeBody(tooltipItems) {
          if (tooltipItems.length <= 0) return "";
          const item = tooltipItems[0];
          return item.label;
        },
        label: () => "",
      },
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        displayFormats: {
          hour: "HH:mm",
          minute: "HH:mm",
        },
      },
      grid: {
        display: true,
        drawTicks: false,
        drawBorder: false,
        drawOnChartArea: true,
        color: "rgb(50, 50, 50)",
      },
      ticks: {
        maxTicksLimit: 4,
        maxRotation: 0,
      },
    },
    y: {
      grid: {
        display: true,
        drawTicks: false,
        drawBorder: false,
        drawOnChartArea: true,
        color: "rgb(50, 50, 50)",
      },
      ticks: {
        includeBounds: false,
        maxTicksLimit: 5,
      },
    },
    xSegment: {
      type: "linear",
      display: false,
      ticks: {
        stepSize: 1,
      },
    },
    xErrorPlots: {
      display: false,
      type: "category",
      grid: {
        display: false,
        drawTicks: false,
        drawBorder: false,
        drawOnChartArea: false,
      },
      offset: true,
      ticks: {
        maxRotation: 0,
      },
    },
    yHighlightValue: {
      display: false,
      position: "right",

      grid: {
        display: true,
        drawTicks: false,
        drawOnChartArea: false,
        drawBorder: false,
        color: "rgb(50, 50, 50)",
      },

      afterSetDimensions: (axis) => {
        axis.maxWidth = 500; // eslint-disable-line no-param-reassign
      },

      ticks: {
        maxRotation: 0,
        font: {
          size: 20,
        },
        maxTicksLimit: 5,

        color: "rgb(255, 255, 255)",
        autoSkip: false,
      },
    },
  },
};

const defaultDataset: ChartData<"line">["datasets"][0] = {
  data: [],
  borderCapStyle: "round",

  pointRadius: 4,
  pointBorderWidth: 0,
  borderWidth: 4,
  pointStyle: "circle",

  fill: {
    above: "transparent",
    below: "transparent",
  },

  pointBackgroundColor: "rgb(255, 255, 255)",
  borderColor: "rgb(111, 117, 126)",
  backgroundColor: "rgb(111, 117, 126)",
};

const defaultErrorBarsDataset: ChartData<"lineWithErrorBars">["datasets"][0] = {
  data: [],
  type: "lineWithErrorBars",

  showLine: false,
  xAxisID: "xErrorPlots",

  errorBarWhiskerSize: 10,
  borderWidth: 2,
  backgroundColor: "rgb(255, 255, 255)",
  borderColor: "rgb(255, 255, 255)",
};

export type GraphConfig = {
  timeSearch?: string;
  timeUnit?: string;
  title?: string;
  axisRangeId?: number;
  timeAxis?: boolean;
  includeInGraphRangeSearch?: string;
  showYTicks?: boolean;
  inspection?: string;

  verticalLines?: {
    color?: string;
    lineWidth?: number;
    array?: boolean;
    search: string;
  }[];

  legend?: {
    display: boolean;
    position?: string;
  };

  highlightValue?: {
    enabled?: boolean;
    search?: string;
    unit?: string;
    decimals?: number;
    color?: string;
  };

  errorPlots?: {
    label?: string;
    pointColor?: string;

    search: string;
    searchErrorA?: string;
    searchErrorB?: string;
  }[];

  datasets?: {
    label?: string;
    search: string | string[];
    stepped?: string;
    single?: boolean;
    tension?: number;
    dash?: number[];

    showOnSearch?: string;

    minRange?: number;

    lineColor?: string;
    pointColor?: string;
    backgroundColor?: string;

    pointStyle?: string;
    pointRadius?: number | number[];
    pointBorderWidth?: number;
    lineWidth?: number;

    legend?: {
      display?: boolean;
      text?: string;
    };

    fill?: {
      target: string | boolean;
      above?: string;
      below?: string;
    };
  }[];
};

export default function Graph({
  config,
  onElementSelected,
}: {
  config: GraphConfig;
  onElementSelected: (val: [string, number] | undefined) => void;
}) {
  const { getValue, getNumbersArray, getNumber, updateTimestamp } =
    useContext(dataContext);
  const { addRange, getRange, addMinRange } = useContext(graphRangeContext);

  const [errorPlotsDataset, setErrorPlotsDataset] = useState<
    ChartDataset<"lineWithErrorBars"> | ChartDataset<"line"> | undefined
  >();
  const [datasets, setDatasets] = useState<
    ChartData<"line">["datasets"] | undefined
  >();

  const addValuesToRange = useCallback(
    (values: any[] | undefined) => {
      if (
        typeof config.axisRangeId === "number" &&
        typeof values !== "undefined"
      ) {
        const cleanValues = values.filter(isValidNumber);
        addRange(
          config.axisRangeId,
          {
            max: Math.max(...cleanValues),
            min: Math.min(...cleanValues),
          },
          updateTimestamp
        );
      }
    },
    [config.axisRangeId, addRange, updateTimestamp]
  );

  useEffect(() => {
    if (!config.errorPlots) return;
    const color = config.errorPlots.map(
      (value) =>
        value.pointColor ?? (defaultErrorBarsDataset.backgroundColor as string)
    );

    const errorPlotData = config.errorPlots.map((value) => ({
      y: getNumber(value.search),
      yMin: value.searchErrorA ? getNumber(value.searchErrorA) : undefined,
      yMax: value.searchErrorB ? getNumber(value.searchErrorB) : undefined,
    }));
    addValuesToRange(
      errorPlotData.flatMap((v) => [v.y, v.yMin, v.yMax]).filter(isValidNumber)
    );

    setErrorPlotsDataset(
      deepmergeIgnoreUndefined(defaultErrorBarsDataset, {
        data: errorPlotData as any,

        errorBarColor: color,
        errorBarWhiskerColor: color,
        borderColor: color,
        backgroundColor: color,
      })
    );
  }, [addValuesToRange, config.errorPlots, getNumber]);

  useEffect(() => {
    if (!config.datasets) return;

    if (typeof config.axisRangeId === "number") {
      addMinRange(
        config.axisRangeId,
        config.datasets
          .map((dataset) => dataset.minRange)
          .filter(isValidNumber)
          .reduce((max, current) => Math.max(max, current), 0)
      );
    }

    setDatasets(
      config.datasets.map((dataset) => {
        let datasetdata: any[] | undefined = [];
        const includeInRange = config.includeInGraphRangeSearch
          ? getValue(config.includeInGraphRangeSearch)
          : undefined;

        let values: number[] | undefined = [];
        if (Array.isArray(dataset.search)) {
          values = dataset.search.map((search) => getNumber(search));
        } else {
          values = getNumbersArray(dataset.search);
        }
        addValuesToRange(
          values?.filter((_, k) =>
            Array.isArray(includeInRange) ? includeInRange[k] : true
          )
        );

        if (values) {
          if (dataset.single) {
            datasetdata = values.map((val, key) => ({
              x: key,
              y: val,
            }));
          } else {
            datasetdata = values;
          }
        }

        if (dataset.showOnSearch) {
          const showOn = getValue(dataset.showOnSearch);
          if (
            showOn &&
            Array.isArray(showOn) &&
            showOn.length === datasetdata.length
          ) {
            datasetdata = datasetdata.map((v, k) => (showOn[k] ? v : null));
          }
        }

        return deepmergeIgnoreUndefined(defaultDataset, {
          label: dataset.label,
          data: datasetdata,
          stepped: dataset.stepped,
          xAxisID: dataset.single ? "xSegment" : "x",
          cubicInterpolationMode: "default",
          tension: dataset.tension,
          borderDash: dataset.dash,
          pointStyle: dataset.pointStyle,

          pointRadius: dataset.pointRadius,
          pointBorderWidth: dataset.pointBorderWidth,
          borderWidth: dataset.lineWidth,

          fill: dataset.fill,

          pointBackgroundColor: dataset.pointColor,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.lineColor,
        } as ChartDataset<"line">);
      })
    );
  }, [
    addValuesToRange,
    addMinRange,
    config.datasets,
    config.includeInGraphRangeSearch,
    config.axisRangeId,
    getNumbersArray,
    getNumber,
    getValue,
  ]);

  const options: ChartOptions<"line"> = useMemo(() => {
    let max: number | undefined;
    let min: number | undefined;

    if (typeof config.axisRangeId === "number") {
      const range = getRange(config.axisRangeId);
      if (range) ({ max, min } = range);
    }

    const annotations = {
      ...(config.verticalLines
        ? config.verticalLines.flatMap((val) => {
            const searched = getValue(val.search);
            if (!searched) return undefined;

            const list = Array.isArray(searched) ? [...searched] : [searched];

            return list.map((x: any) => ({
              type: "line",
              xMin: x * 1000,
              xMax: x * 1000,
              borderColor: val.color ?? "rgb(255, 255, 255)",
              borderWidth: val.lineWidth ?? 2,
              xScaleID: "x",
            }));
          })
        : []),
    };

    return deepmergeIgnoreUndefined(defaultOptions, {
      plugins: {
        annotation: {
          annotations,
        },
        legend: {
          display: config.legend?.display,
          position: config.legend?.position,
          labels: {
            filter(item) {
              if (!config.datasets) return true;
              const ds = config.datasets[item.datasetIndex];
              if (!ds || !ds.legend) return true;

              if (ds.legend.text) {
                item.text = ds.legend.text; // eslint-disable-line no-param-reassign
              }

              return (
                typeof ds.legend.display === "undefined" || ds.legend.display
              );
            },
          },
        },
        title: {
          display: typeof config.title !== "undefined",
          text: config.title,
        },
        tooltip: {
          callbacks: {
            title(tooltipItems) {
              if (tooltipItems.length <= 0) return "";

              const item = tooltipItems[0];
              const element = item.dataset.data[item.dataIndex] as any;
              if (typeof element === "undefined" || element === null) return "";

              let title = "";

              if (typeof element === "number") {
                title = title.concat(element.toPrecision(4));
              } else {
                if (Object.prototype.hasOwnProperty.call(element, "y")) {
                  title = title.concat(element.y.toPrecision(4));
                }

                if (
                  typeof element.yMin === "number" &&
                  typeof element.yMax === "number"
                ) {
                  title = title.concat(
                    ` [${element.yMin.toPrecision(
                      4
                    )}; ${element.yMax.toPrecision(4)}]`
                  );
                }
              }

              if (item.dataset.label) {
                title = formatter(
                  item.dataset.label.replaceAll("{}", title),
                  getValue
                );
              }

              return title;
            },
          },
        },
      },
      scales: {
        x: {
          display:
            typeof config.timeSearch !== "undefined" &&
            (typeof config.timeAxis === "undefined" || config.timeAxis),
          time: {
            unit: config.timeUnit,
          },
        },
        y: {
          ticks: {
            display:
              typeof config.showYTicks === "undefined" || config.showYTicks,
          },
          min,
          max,
        },
        xErrorPlots: {
          display: typeof config.errorPlots !== "undefined",
          labels: config.errorPlots
            ? config.errorPlots.map((value) =>
                formatter(value.label ?? "", getValue)
              )
            : [],
        },
        yHighlightValue: {
          display: config.highlightValue && config.highlightValue.enabled,
          min,
          max,

          afterBuildTicks: (axis) => {
            if (!config.highlightValue || !config.highlightValue.search) return;
            // eslint-disable-next-line no-param-reassign
            axis.ticks = [{ value: getNumber(config.highlightValue.search) }];
          },
          ticks: {
            callback: (tickValue) => {
              const val =
                typeof tickValue === "number"
                  ? tickValue.toFixed(config.highlightValue?.decimals)
                  : tickValue;
              return `${val ?? "-"} ${formatter(
                config.highlightValue?.unit ?? "",
                getValue
              )}`;
            },
            color:
              config.highlightValue && config.highlightValue.color
                ? formatter(config.highlightValue?.color, getValue)
                : undefined,
          },
        },
      },
    } as ChartOptions<"line">);
  }, [config, getRange, getNumber, getValue]);

  const data = useMemo(() => {
    const ret: ChartData = { datasets: [] };
    if (errorPlotsDataset)
      ret.datasets = ret.datasets.concat(errorPlotsDataset);
    if (datasets) ret.datasets = ret.datasets.concat(datasets);

    if (config.timeSearch) {
      const rawTimes = getNumbersArray(config.timeSearch);
      if (rawTimes) ret.labels = rawTimes.map((time: number) => time * 1000);
    }

    return ret;
  }, [config.timeSearch, getNumbersArray, datasets, errorPlotsDataset]);

  const ref = useRef();
  const openInspection = useCallback(
    (ev: any) => {
      if (!ref.current) return;
      const chart: ChartJS<"line"> = ref.current;

      if (config.inspection) {
        const elems = getElementAtEvent(chart, ev);
        if (elems && elems.length > 0) {
          onElementSelected([config.inspection, elems[0].index]);
          window.scrollTo(0, 0);
          return;
        }
      }

      onElementSelected(undefined);
    },
    [config.inspection, onElementSelected]
  );

  return (
    <div className="relative mx-auto lg:h-full w-full lg:w-[99%]">
      <Line
        onClick={(ev) => {
          if (window.matchMedia("(pointer: coarse)").matches) return; // Ignore single clicks on touch-screens
          openInspection(ev);
        }}
        onDoubleClick={openInspection}
        ref={ref}
        data={data as ChartData<"line">}
        options={options}
      />
    </div>
  );
}
